import React, { useState, useEffect } from 'react';

const MapNodeDetails = ({ currentNodeDetail,  setCurrentNodeDetail, mapNodeSet, addMapNode, getMapNode, selected, moving }) => {
  const [mapNode, setMapNode] = useState(getMapNode(selected));
  useEffect(
    () => {
      if (selected === "moving") { } else { addMapNode(mapNode) };
    },
    [mapNode]
  );

  const isParent = (sourceKey, destKey) => {
    const sourceNode = mapNodeSet.find((mapNode) => mapNode.key === sourceKey);
    if (sourceNode.links.includes(destKey) || sourceNode.links.map((linkKey) => { return isParent(linkKey, destKey)}).includes(true)) {
      return true
    } else {
      return false
    }
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        if (selected === "moving") { } else { setMapNode(currentNodeDetail) };
      }}
    >
      {selected !== "moving" &&
        <>
          <label>Key: </label>{selected}<br />
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={currentNodeDetail.name}
            onChange={event => { setCurrentNodeDetail({ ...currentNodeDetail, ...{ name: event.target.value } }) }}
            onBlur={event => { setMapNode({ ...currentNodeDetail, ...{ name: event.target.value } }) }}
            onKeyPress={ event => { if(event.key === 'Enter') { setMapNode({ ...currentNodeDetail, ...{ name: event.target.value}}) }}}
          />
          <br />
          <label>nodeType: </label>
          <select
            value={currentNodeDetail.nodeType}
            onChange={
              event => {
                setCurrentNodeDetail({ ...currentNodeDetail, ...{ nodeType: event.target.value } });
                setMapNode({ ...currentNodeDetail, ...{ nodeType: event.target.value } });
              }
            }
          >
            <option value="mapNode">map node</option>
            <option value="userNeed">user need</option>
            <option value="subMap">sub map</option>
          </select>
          <br />
          <br />
          <label> x value: </label>
            <input
              type="text"
              name="x"
              value={currentNodeDetail.x}
              onChange={event => { setCurrentNodeDetail({ ...currentNodeDetail, ...{ x: parseInt(event.target.value) } }) }}
              onBlur={event => { setMapNode({ ...currentNodeDetail, ...{ x: parseInt(event.target.value) } }) }}
            />
          <br />
          <label> y value: </label>
            <input
              type="text"
              name="y"
              value={currentNodeDetail.y}
              onChange={event => { setCurrentNodeDetail({ ...currentNodeDetail, ...{ y: parseInt(event.target.value) } }) }}
              onBlur={event => { setMapNode({ ...currentNodeDetail, ...{ y: parseInt(event.target.value) } }) }}
            /><br />
          <br />
          <label>links to: </label><br />
          {currentNodeDetail.links.map((nodeKey) => { return <>{getMapNode(nodeKey).name} </> })}
          <br />
          {mapNodeSet.filter((mapNode) => mapNode.key !== currentNodeDetail.key && mapNode.nodeType !== "userNeed" && ! isParent(mapNode.key, selected) ).map((mapNode) =>
            <label>
              <input
                type="checkbox"
                id={mapNode.key}
                value={mapNode.key}
                checked={currentNodeDetail.links.filter((entry) => entry === mapNode.key).length > 0 ? true : ""}
                onChange={() => {
                  if (currentNodeDetail.links.filter((entry) => entry === mapNode.key).length > 0) {
                    setCurrentNodeDetail({
                      ...currentNodeDetail,
                      ...{ links: currentNodeDetail.links.filter((link) => link !== mapNode.key) }
                    });
                    setMapNode({
                      ...currentNodeDetail,
                      ...{ links: currentNodeDetail.links.filter((link) => link !== mapNode.key) }
                    });
                  } else {
                    setCurrentNodeDetail({
                      ...currentNodeDetail,
                      ...{ links: [...currentNodeDetail.links, mapNode.key].sort() }
                    });
                    setMapNode({
                      ...currentNodeDetail,
                      ...{ links: [...currentNodeDetail.links, mapNode.key].sort() }
                    });
                  };
                }}
              />
              {mapNode.name} ({mapNode.key})
              <br />
            </label>
          )}
          <br />
        </>}
    </form >
  )
}

export default MapNodeDetails;