import React from 'react';


const MapConnectorList = ({ mapNodeSet, addMapNode, getMapNode, mapConnectors, selected, setSelected }) => {

  const removeLink = (mapConnector) => {
    console.log(mapNodeSet);
    addMapNode({ // combine the objects
      ...getMapNode(mapConnector.sourceNode),
      ...{ links: getMapNode(mapConnector.sourceNode).links.filter((_) => _ !== mapConnector.destNode) }
    });
  }
  return (
    <ul>
      {mapConnectors.map((mapConnector) => {
        return (
          <li
            className={["mapNodeListItem", mapConnector.sourceNode === selected ? "selected" : ""].join(" ")}
            key={mapConnector.key}
          >
            <button
            type="button"
            onClick={event => {
              removeLink(mapConnector);
              setSelected(mapConnector.sourceNode);
            }}
            > x </button>&nbsp;
            {getMapNode(mapConnector.sourceNode).name} links to {getMapNode(mapConnector.destNode).name}
          </li>)
      }
      )}
    </ul>
  )
};

export default MapConnectorList;