import React from 'react';
import './MapNode.css';

import MapNode from './MapNode';

const MapNodeSet = ({ mapNodeSet, addMapNode, getMapNode, selected, setSelected, moving, setMoving }) => {

  return (
    <g className="mapNodes">
      {mapNodeSet.map((mapNode) => (
        <MapNode
          key={mapNode.key}
          mapNodeKey={mapNode.key}
          addMapNode={addMapNode}
          getMapNode={getMapNode}
          selected={selected}
          setSelected={setSelected}
          moving={moving}
          setMoving={setMoving}
        />
      ))}
    </g>
  )
};

export default MapNodeSet;