import { useState } from 'react';

export default (initialValues, addMapNode, mapNodeSet, selected) => {
  
  const isParent = (sourceKey, destKey) => {
    const sourceNode = mapNodeSet.find((mapNode) => mapNode.key === sourceKey);
    if (sourceNode.links.includes(destKey)|| sourceNode.links.map((linkKey) => { return isParent(linkKey, destKey)}).includes(true)) {
      return true
    } else {
      return false
    }
  }

  const ensureHeirarchy = (sourceKey, destKey) => {
    const sourceNode = mapNodeSet.filter((mapNode) => mapNode.key === sourceKey)[0];
    const destNode = mapNodeSet.filter((mapNode) => mapNode.key === destKey)[0];
    
    if (sourceNode.y >= destNode.y ) {
      console.log(sourceKey, " ", destKey, " ", selected);
      if (sourceKey === selected || isParent(selected, sourceKey)) {
        addMapNode({ ...destNode, ...{ y: sourceNode.y + 25 }});
      } else if (destKey === selected || isParent(destKey, selected)) {
      addMapNode({ ...sourceNode, ...{ y: destNode.y - 25 }});
      }
    }
  }

  const nodesWithLinks = initialValues.filter((_) => 'links' in _);

  const mapConnectorSet = nodesWithLinks.map((mapNode) => {
    const linkSet = mapNode.links.map((target) => {
      return ({ key: [mapNode.key, target].join("-"), sourceNode: mapNode.key, destNode: target });
    })
    return linkSet;
  }).flat();

  const [mapConnectors, setMapConnectors] = useState(mapConnectorSet);

  return {
    mapConnectors,
    deleteMapConnectorsForNode: mapNodeKey => {
      const newMapConnectors = mapConnectors.filter((_, id) => (_.sourceNode !== mapNodeKey) && (_.destNode !== mapNodeKey));
      setMapConnectors(newMapConnectors);
    },
    refreshMapConnectors: (mapNodes, selected) => {
      const newMapNodes = mapNodes.filter(
        (_) => 'links' in _
      ).map(
        (mapNode) => {
          const linkSet = mapNode.links.map((target) => {
            ensureHeirarchy(mapNode.key, target);
            return ({ key: [mapNode.key, target].join("-"), sourceNode: mapNode.key, destNode: target });
          })
          return linkSet;
        }).flat()
        
      setMapConnectors(newMapNodes);
    }
  };
};