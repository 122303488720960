import React, { useState, useEffect } from 'react';
import MapNodeSet from './MapNodeSet';
import MapConnectorSet from './MapConnectorSet';
import MapNodeDetails from './MapNodeDetails';
import MapNodeList from './MapNodeList';

import './MapBackground.css';

import useMapNodeSet from './useMapNodeSet';
import useMapConnectors from './useMapConnectors';
import MapConnectorList from './MapConnectorList';

const MapBackground = () => {
  const { mapNodeSet, addMapNode, getMapNode, deleteMapNode } = useMapNodeSet(
    [
      // { key: "a", x: 50, y: 50, nodeType: "userNeed", links: ["d", "e"] },
      // { key: "b", x: 600, y: 100, nodeType: "mapNode", links: ["d", "f"] },
      // { key: "c", x: 900, y: 400, nodeType: "mapNode", links: ["e"] },
      // { key: "d", x: 600, y: 200, nodeType: "userNeed", links: ["e"] },
      // { key: "e", x: 350, y: 250, nodeType: "mapNode", links: [] },
      // { key: "f", x: 800, y: 300, nodeType: "subMap", links: ["c", "g"] },
      // { key: "g", x: 400, y: 400, nodeType: "subMap", links: [] }
    ]
  );

  

  const [currentNodeDetail, setCurrentNodeDetail] = useState({ key: "c", x: 900, y: 400, nodeType: "mapNode", links: [] });

  const [moving, setMoving] = useState(true);
  
  useEffect(() => {
    if (moving) {
      setCurrentNodeDetail({ key: "moving", x: 0, y: 0, nodeType: "mapNode", links: [] });
    } else {
      setCurrentNodeDetail(getMapNode(selected));
    }
  }, [moving]);

  const [selected, setSelected] = useState("moving");
  useEffect(() => {
    if (selected === "moving") {
      setCurrentNodeDetail({ key: "moving", x: 0, y: 0, nodeType: "mapNode", links: [] });
    } else {
      setCurrentNodeDetail(getMapNode(selected));
    }
  }, [selected]);


  const { mapConnectors, deleteMapConnectorsForNode, refreshMapConnectors } = useMapConnectors(mapNodeSet, addMapNode, mapNodeSet, selected);
  useEffect(() => {
    refreshMapConnectors(mapNodeSet, selected);
  }, [mapNodeSet]);

  const verticalLine = (x, className) => {
    return (
      <line
        x1={x}
        x2={x}
        y1="0"
        y2="1000"
        className={className}
      />
    );
  };

  const horizontalLine = (y, className) => {
    return (
      <line
        y1={y}
        y2={y}
        x1="0"
        x2="1000"
        className={className}
      />
    );
  };

  return (
    <div className="MapBackground">
      <h1> Mapperific</h1>
      <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="500" className="MapBackground">
        <g>
          {verticalLine(0, "EdgeDivider")}
          {verticalLine(250, "SubDivider")}
          {verticalLine(500, "SubDivider")}
          {verticalLine(750, "SubDivider")}
          {horizontalLine(500, "EdgeDivider")}
        </g>
        <MapConnectorSet
          mapNodeSet={mapNodeSet}
          mapConnectors={mapConnectors}
          getMapNode={getMapNode}
          selected={selected}
        />
        <MapNodeSet
          mapNodeSet={mapNodeSet}
          addMapNode={addMapNode}
          getMapNode={getMapNode}
          deleteMapNode={deleteMapNode}
          deleteMapConnectorsForNode={deleteMapConnectorsForNode}
          selected={selected}
          setSelected={setSelected}
          moving={moving}
          setMoving={setMoving}
        />
      </svg>
      <div className="MapNodeList">
        <h2>nodes</h2>
        <MapNodeList
          mapNodeSet={mapNodeSet}
          addMapNode={addMapNode}
          deleteMapNode={deleteMapNode}
          selected={selected}
          setSelected={setSelected}
          moving={moving}
          setMoving={setMoving}
        />
        <div className="MapConnectorList">
          <h2>links</h2>
          <MapConnectorList
            mapNodeSet={mapNodeSet}
            addMapNode={addMapNode}
            mapConnectors={mapConnectors}
            selected={selected}
            setSelected={setSelected}
            getMapNode={getMapNode}
          />
        </div>

      </div>
      <div className="MapNodeDetails">
        <MapNodeDetails
          mapNodeSet={mapNodeSet}
          selected={selected}
          setSelected={setSelected}
          currentNodeDetail={currentNodeDetail}
          setCurrentNodeDetail={setCurrentNodeDetail}
          addMapNode={addMapNode}
          getMapNode={getMapNode}
          deleteMapNode={deleteMapNode}
          refreshMapConnectors={refreshMapConnectors}
          moving={moving}
        />
      </div>
    </div >
  );
};

export default MapBackground;


