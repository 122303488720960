import React from 'react';
import './MapConnector.css';

const MapConnectorSet = ({ mapConnectors, getMapNode, selected }) => (
  <g className="mapConnectors">
    {mapConnectors.map((mapConnector, id) => (
      <line
        x1={getMapNode(mapConnector.sourceNode).x}
        y1={getMapNode(mapConnector.sourceNode).y}
        x2={getMapNode(mapConnector.destNode).x}
        y2={getMapNode(mapConnector.destNode).y}
        id={[mapConnector.sourceNode,mapConnector.destNode].join(":")}
        key={id}
        className={["connector", "mapNodeListItem", mapConnector.sourceNode === selected ? "selected" : ""].join(" ")}
      />
    ))}
  </g>
);

export default MapConnectorSet;