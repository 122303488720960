import React from 'react';

import './MapNodeList.css';


const MapNodeList = ({ mapNodeSet, addMapNode, deleteMapNode, selected, setSelected, moving, setMoving }) => {

  return (
    <>
    <label>Add a </label>
    <button
        type="button"
        onClick={event => {
          setSelected(addMapNode({ x: 150, y: 50, nodeType: "userNeed", name: "new user need" }));
        }}> user need </button>
    <button
        type="button"
        onClick={event => {
          setSelected(addMapNode({ x: 150, y: 450, nodeType: "MapNode", name: "new node" }));
        }}> node </button>
    <ul>
      {mapNodeSet.map((mapNode) => (
        <li
          className="mapNodeListItem"
          key={mapNode.key}
        >
          <button
            type="button"
            onClick={event => {
              deleteMapNode(mapNode.key);
              setMoving(true);
            }}
          > x </button>&nbsp;
          <span onClick={() => { setSelected(mapNode.key) }}>
            <span className={["nodeType", mapNode.key === selected ? "selected" : ""].join(" ")}>{mapNode.nodeType}</span>
            <span className={["nodeName", mapNode.key === selected ? "selected" : ""].join(" ")}>{mapNode.name}</span>
          </span>
        </li>


      ))}
    </ul>
    </>
  )
};

export default MapNodeList;