import React from 'react';
import { useRef } from 'react';
import './MapNode.css';




export default ({ mapNodeKey, getMapNode, addMapNode, selected, setSelected, moving, setMoving }) => {

  const coords = useRef({ x: 0, y: 0 });

  const handleMouseDown = (evt) => {
    setSelected(mapNodeKey);
    document.addEventListener('mouseup', handleMouseUp);
    coords.current = ({
      x: evt.pageX,
      y: evt.pageY
    });
    console.log("x: ", coords.current['x'], " y: ", coords.current['y']);
    document.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseUp = () => {
    setSelected(mapNodeKey);
    setMoving(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const numberBounds = (val, min, max) => {
    if (val <= min) {
      return min
    } else if (val >= max) {
      return max
    } else {
      return val;
    };
  };

  const handleMouseMove = (evt) => {
    const xDiff = evt.pageX - coords.current['x'];
    const yDiff = evt.pageY - coords.current['y'];

    console.log("X/ ", xDiff, " Y/ ", yDiff);
    console.log("x: ", coords.current['x'], " y: ", coords.current['y']);

    addMapNode({
      ...getMapNode(mapNodeKey),
      ...{
        x: numberBounds(getMapNode(mapNodeKey).x + xDiff, (2 * size), (1000 - (2 * size))),
        y: numberBounds(getMapNode(mapNodeKey).y + yDiff, size, (500 - (2 * size))),
      }
    });
    setMoving(true);
  };

  const size = 20;

  return (
    <g
      onMouseDown={handleMouseDown}
      onClick={() => { setSelected(getMapNode(mapNodeKey).key) }}
      className={[getMapNode(mapNodeKey).nodeType, mapNodeKey === selected ? "selected" : ""].join(" ")}
    >
      <circle
        id={getMapNode(mapNodeKey).key}
        cx={getMapNode(mapNodeKey).x}
        cy={getMapNode(mapNodeKey).y}
        // cx={size / 2}
        // cy={size / 2}
        r={(size / 2) - 2}
        className={[getMapNode(mapNodeKey).nodeType, mapNodeKey === selected ? "selected" : ""].join(" ")}
      // onMouseDown={handleMouseDown}
      // onClick={() => {setSelected(getMapNode(mapNodeKey).key)}}
      />
      {getMapNode(mapNodeKey).nodeType === "subMap" &&
        <rect
          x={getMapNode(mapNodeKey).x - size / 4}
          y={getMapNode(mapNodeKey).y - size / 4}
          width={size / 2}
          height={size / 2}
        className={[getMapNode(mapNodeKey).nodeType, mapNodeKey === selected ? "selected" : ""].join(" ")}
        // onClick={() => {setSelected(getMapNode(mapNodeKey).key)}}
        />}
      <text
        id={getMapNode(mapNodeKey).key}
        x={getMapNode(mapNodeKey).x}
        y={getMapNode(mapNodeKey).y + (size)}
        className={[getMapNode(mapNodeKey).nodeType, mapNodeKey === selected ? "selected" : ""].join(" ")}
        onClick={() => {
          setSelected(getMapNode(mapNodeKey).key);
        }}
        onMouseDown={handleMouseDown}
      >{getMapNode(mapNodeKey).name}</text>
    </g>
  );
};

