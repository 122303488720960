import { useState } from 'react';

export default (initialValues) => {

  const newKey = () => {
    var dt = new Date().getTime();
    const key = 'xxxx-4xxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(12);
    });
    return key;
  };
  
  const emptyNode = {
    key: newKey(),
    name: "new node",
    x: 50,
    y: 50,
    nodeType: "mapNode",
    links: []
  };

  const initialNodes = initialValues.map((node) => { return { ...emptyNode, ...node } });

  const [mapNodeSet, setMapNodeSet] = useState(initialNodes);

  const ensureHeirarchy = (sourceKey, destKey) => {
    const sourceNode = mapNodeSet.filter((mapNode) => mapNode.key === sourceKey)[0];
    const destNode = mapNodeSet.filter((mapNode) => mapNode.key === destKey)[0];
    
    if (sourceNode.y >= destNode.y ) {
      addMapNode({ ...destNode, ...{ y: sourceNode.y + 25 }});
      addMapNode({ ...sourceNode, ...{ y: destNode.y + 25 }});
      }
  };

  const addMapNode = (mapNodeToAdd) => {
    console.log(mapNodeSet);
    console.log("asked to add ", mapNodeToAdd);

    mapNodeToAdd = { ...emptyNode, ...mapNodeToAdd };
    console.log("Adding ", mapNodeToAdd);

    const i = mapNodeSet.findIndex((element) => { return element.key === mapNodeToAdd.key })
    if (i !== -1) {
      const newMapNodeSet =
        [
          ...mapNodeSet.slice(0, i),
          mapNodeToAdd,
          ...mapNodeSet.slice(i + 1)
        ];
        console.log(newMapNodeSet);
        setMapNodeSet(newMapNodeSet);
    } else {
      const newMapNodeSet =
        [
          ...mapNodeSet.filter((_, id) => _.key !== mapNodeToAdd.key),
          mapNodeToAdd
        ];
      console.log(newMapNodeSet);
      setMapNodeSet(newMapNodeSet);
    }
    

    return (mapNodeToAdd.key);
  };

  const getMapNode = (key) => {
    if (mapNodeSet.findIndex((element) => {
      return element.key === key;
    }) >= 0) {
      return mapNodeSet.filter((mapNode) => key === mapNode.key)[0];
    } else {
      return mapNodeSet[0];
    }
  };

  const deleteMapNode = (mapNodeKey) => {
    console.log("deleting: ", mapNodeKey);
    const newMapNodes = mapNodeSet.filter(
      (mapNode) => (mapNode.key !== mapNodeKey)
    ).map(
      (mapNode) => {
        if ('links' in mapNode) {
          return ({ ...mapNode, ...{ links: mapNode.links.filter((link) => link !== mapNodeKey) }}
          )
        } else {
          return (mapNode)
        }
      }
    );
    console.log("newMapNodes ", newMapNodes);
    setMapNodeSet(newMapNodes);
  };

  return {
    mapNodeSet,
    setMapNodeSet,
    addMapNode,
    getMapNode,
    deleteMapNode
  };
};